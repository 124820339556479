/* ResenasGoogle.css */

.resenas-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 4rem auto;
    max-width: 800px;
    padding: 2rem;
    text-align: left;
    background-color: #ffffff;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    border-radius: 8px;
}

.resena-card {
    margin: 20px;
    width: 300px;
    transition: transform 0.2s;
    min-width: 275px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.resena-card:hover {
    transform: scale(1.05);
}

.resena-card h5 {
    color: #333;
    margin-top: 0;
}

.resena-card p {
    color: #666;
    font-size: 14px;
}

.star-icon {
    color: yellow;
}