/* src/App.css */
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #282c34; /* Fondo oscuro para contraste */
}

/* Animación del logo */
.App-logo {
  height: 40vmin;
  animation: App-logo-spin infinite 20s linear;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Cabecera modernizada con un toque de gradiente */
.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  background: linear-gradient(145deg, #000, #282c34); /* Gradiente sutil */
}

/* Enlace con efecto de hover mejorado */
.App-link {
  color: #61dafb;
  transition: color 0.5s ease-in-out;
}

.App-link:hover {
  color: #ffcc00; /* Cambio a amarillo al pasar el ratón */
}

/* Navegación ultramoderna */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  background-color: #a8abb1;
}

.logo {
  color: rgb(27, 26, 26);
  text-shadow: 2px 2px 4px rgb(224, 166, 19);
  font-size: 3rem;
}
.navbar ul {
  list-style-type: none;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparente */
  padding: 10px 30px;
  border-radius: 25px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.2); /* Sombra suave */
}

.navbar ul li {
  margin: 0 15px;
}

.navbar ul li a {
  text-decoration: none;
  color: white;
  font-size: 16px;
  transition: color 0.3s ease-in-out;
}

.navbar ul li a:hover {
  color: #ffcc00; /* Amarillo al pasar el ratón */
}

/* Ajustes para dispositivos móviles */
@media (max-width: 768px) {
  .navbar ul {
    flex-direction: column; /* Cambio a columna en pantallas pequeñas */
    padding: 20px;
  }

  .navbar ul li {
    margin: 10px 0;
  }
}

/* Añade esto a tu App.css existente */

.section-style {
  margin: 4rem auto;
  max-width: 800px;
  padding: 2rem;
  text-align: left;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  border-radius: 8px;
}

.section-style h2 {
  color: #ffcc00;
  margin-bottom: 1rem;
}

.section-style p {
  color: #333;
  line-height: 1.6;
}

/* Añade esto a tu App.css existente */

.services-list, .prices-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.service-item, .price-item {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.service-item h3, .price-item h3 {
  color: #333;
  margin-top: 0;
}

.service-item p, .price-item p {
  color: #666;
  font-size: 14px;
}

.logos-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* Ajusta el espacio entre logos */
  padding: 20px;
}

.logos-container img {
  height: 140px; /* O cualquier tamaño que se ajuste mejor */
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.card {
  margin: 20px;
  width: 300px;
  transition: transform 0.2s;
}

.card:hover {
  transform: scale(1.05);
}

.carrusel{
  display: flex;
  overflow-x: auto;
  margin-right: 5vw;
  margin-left: 5vw;
  padding: 20px;
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
  }

  .navbar ul {
    flex-direction: row;
    padding: 0; /* Eliminar padding */
  }

  .navbar ul li {
    margin: 2vw; /* Eliminar margen */
  }

  .logos-container {
    flex-direction: column;
    align-items: center;
  }


  .container {
    flex-direction: column;
    align-items: center;
  }

  .card {
    width: 100%; /* Hacer que las tarjetas ocupen todo el ancho */
    margin: 0; /* Eliminar margen */
  }

  .section-style {
    margin: 0; /* Eliminar margen */
    padding: 0; /* Eliminar padding */
  }

  .service-item, .price-item {
    padding: 0; /* Eliminar padding */
  }
  .carrusel{
    margin-right: 0;
    margin-left: 0;
  }
}

@media (max-width: 480px) {
  .navbar ul {
    padding: 0; /* Eliminar padding */
  }

  .navbar ul li {
    margin: 2vw; /* Eliminar margen */
  }


  .card {
    margin: 0; /* Eliminar margen */
  }

  .section-style {
    margin: 0; /* Eliminar margen */
    padding: 0; /* Eliminar padding */
  }

  .service-item, .price-item {
    padding: 0; /* Eliminar padding */
  }
  .carrusel{
    margin-right: 0;
    margin-left: 0;
  }
}