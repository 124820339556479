

.container {
    position: absolute;
    right: 0;
    top: 20px;
    height: calc(100% - 40px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end; /* Asegura que el área de entrada siempre esté en la parte inferior */
    padding: 20px;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
    background-color: white;
    box-shadow: -10px 0px 20px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}

.response-area {
    flex-grow: 1;
    overflow-y: auto; /* Permite el desplazamiento si el contenido es demasiado largo */
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    max-height: 50vh; /* Establece un límite máximo para el área de chat */
}

.chat-wrapper {
    position: fixed;
    right: 0;
    top: 0;
    height: 70vh;
    width: 20vw;
    overflow: hidden;
    visibility: hidden; /* Oculta el chat por defecto */
    transform: translateX(100%);
    transition: transform 0.2s ease-out;
}

.chat-wrapper.open {
    visibility: visible;
    transform: translateX(0%);
}

.chat-button {
    position: fixed;
    right: 20px;
    bottom: 20px;
    padding: 10px 20px;
    border: none;
    color: white;
    border-radius: 10px;
    cursor: pointer;
}

.logoChat{
    width: 5vw;
    height: auto;
}
@media screen and (max-width: 768px) {
    .logoChat{
        width: 20vw;
    }
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
    border: none;
    background-color: #ffffff;
    color: white;
    border-radius: 10px;
    cursor: pointer;
}

.prompt-area {
    display: flex;
    justify-content: space-between;
}


@media screen and (max-width: 768px) {
    .chat-wrapper {
        width: 90vw; /* Cambia el ancho a 90% en pantallas pequeñas */
    }
    .container{
        width: 100%;
    }
}

.prompt-area input {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
}

.prompt-area button {
    margin-left: 10px;
    padding: 10px 20px;
    border: none;
    background-color: #007BFF;
    color: white;
    border-radius: 10px;
    cursor: pointer;
}

.prompt-area button:hover {
    background-color: #0056b3;
}

.message {
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 10px;
}

.user-message {
    align-self: flex-end;
    background-color: #007BFF;
    color: white;
}

.gpt-message {
    align-self: flex-start;
    background-color: #eee;
}